import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { v4 as uuidV4 } from 'uuid';

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';

import { CustomerInterface } from '../../../../../../domain/Customer/Customer';
import { ReferentielInterface } from '../../../../../../domain/Referentiel/ReferentielInterface';
import { useAppDispatch, useAppSelector } from '../../../../../store/hook';

import { CommunicationInterface } from '../../../../../../domain/Customer/Communication';
import { FiscalityInterface } from '../../../../../../domain/Customer/Fiscality';
import {useNavigate, useParams} from 'react-router-dom';
import ReferentielGateway from '../../../../../../gateway/Referentiel/ReferentielGateway';
import InitializePerson from '../../../../../../useCase/Customer/Initialization/InitializePerson';
import UpdateUseCase from '../../../../../../useCase/Customer/Update/UpdateUseCase';
import CustomerGateway from '../../../../../../gateway/Customer/CustomerGateway';
import AddUseCase from '../../../../../../useCase/Customer/Add/AddUseCase';
import CityDatalist from '../../../Datalist/City/CityDatalist';
import { City } from '../../../../../../domain/Referentiel/City/City';
import { Country } from '../../../../../../domain/Referentiel/Country/Country';
import CountryDatalist from '../../../Datalist/Country/CountryDatalist';
import Input from '../../../Elements/Input';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmationModal from '../../../Modal/ConfirmationModal';
import { toastError, toastSuccess } from '../../../../util/Toast';
import { usePrompt } from '../../../../util/Navigation';
import SelectCustom from '../../../Elements/Select';
import { setReadOnlyFormElements } from '../../../../util/setReadOnlyFormElements';
import InputDate from '../../../Elements/InputDate';
import DocumentGateway from '../../../../../../gateway/Prospect/Document/DocumentGateway';
import FormErrorInfo from "../../../Alert/FormErrorInfo/FormErrorInfo";
import CheckboxToggle from '../../../Elements/CheckboxToggle';
import DocumentUtils from "../../../../util/DocumentUtils";
import DocumentForm from "../../Document/Form";
import FooterBlock from "../../../Block/FooterBlock";
import Checkbox from '../../../Elements/Checkbox';
import RadioYesOrNot from '../../../Elements/RadioYesOrNot';
import {DocumentInterface, DocumentType} from "../../../../../../domain/Document/DocumentInterface";
import DocumentHistoryBlock from "../../../Block/DocumentHistoryBlock";

interface IFormInput {
  customerCode: string | null;
  externalDepositaryCode: string | null;
  externalCode: string | null;
  recoveryCode: string | null;
  moralType: string | null;
  legalStatus: string | null;
  companyName: string | null;
  siret: string | null;

  managerLastname: string | null;
  managerFirstname: string | null;
  managerPpe: boolean | null;
  managerPpeCategory: string | null;
  managerBirthdate: string | null;
  managerBirthCity: City | null;
  managerNationality: Country | null;
  managerBirthCountry: Country | null;
  managerExtranet: string | null;
  managerExtranetCreatedAt: string | null;

  fiscality: FiscalityInterface | null;

  kycCategoryValue: string | null;
  kycCategoryDate: string | null;
  kycLabft: string | null;
  kycLabftDate: string | null;
  kycProfession: string | null;
  kycFatca: boolean | null;

  optinMarketing: CommunicationInterface | null;
  optinDividend: CommunicationInterface | null;
  optinFiscality: CommunicationInterface | null;
  optinGeneralAssembly: CommunicationInterface | null;
  optinInvitation: CommunicationInterface | null;
  optinAll: CommunicationInterface | null;

  personEmail: string | null;
  personPhone: string | null;

  statusCreatedAt: string | null;
  status: string | null;
  exitedAt: string | null;
  blockedAt: string | null;

  fiscalityIncomeTax: string | null;
  fiscalityBusinessTaxt: string | null;
  fiscalitySubjectIfi: string | null;

  documents: {
    [key: string] : {
      [key: string] : {
        date: string
        updatedAt: string
      }
    }
  }
}

type Props = {
  setStatus: React.Dispatch<React.SetStateAction<string>>
  referential: ReferentielInterface
  customer: CustomerInterface
  isLectureMode?: boolean
  handler?: any
}


const GeneralInformationMoralPerson: FunctionComponent<Props> = ({ customer, isLectureMode, handler, referential, setStatus }) => {
  const { t } = useTranslation();
  const { customerType } = useParams();

  const countryByDefault = useAppSelector(({referential}) => referential.countryByDefault)
  const customerStore = useAppSelector((state) => state.customer);
  const [formErrors, setFormErrors] = useState<string[]>([])
  const [documentArray, setDocumentArray] = useState<DocumentType>({})
  const [documentHistoryArray, setDocumentHistoryArray] = useState<DocumentInterface[]>([])
  const [defaultCountry, setDefaultCountry] = useState<Country[]>([])
  const openUploadForm = useAppSelector((state) => state.event.openUploadForm)
  const documentUtils = new DocumentUtils()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setValue('customerCode', customer.customerCode);
    setValue('externalDepositaryCode', customer.externalDepositaryCode);
    setValue('externalCode', customer.externalCode);
    setValue('moralType', customer.moralType);
    setValue('recoveryCode', customer.recoveryCode);
    setValue('legalStatus', customer.legalStatus);
    setValue('companyName', customer.companyName);
    setValue('siret', customer.siret);
    setValue('fiscality', customer.fiscality);
    setValue('kycCategoryValue', customer.kycCategoryValue);
    setValue('kycCategoryDate', customer.kycCategoryDate);
    setValue('kycLabft', customer.kycLabft);
    setValue('kycLabftDate', customer.kycLabftDate);
    setValue('kycProfession', customer.kycProfession);
    setValue('kycFatca', customer.kycFatca);
    setValue('statusCreatedAt', customer.createdAt);
    setValue('status', customer.status);
    setValue('exitedAt', customer.exitedAt);
    setValue('blockedAt', customer.blockedAt);

    setValue('fiscality.taxResidency', (customer && customer.persons && customer.persons.length > 0 && customer.persons[0].fiscality && customer.persons[0].fiscality.taxResidency) ? customer.persons[0].fiscality?.taxResidency : 'france');
    setValue('fiscalityBusinessTaxt', (customer && customer.persons && customer.persons.length > 0 && customer.persons[0].fiscality && customer.persons[0].fiscality) ? (customer.persons[0].fiscality.businessTax) ? '1' : '0' : '1');
    setValue('fiscalityIncomeTax', (customer && customer.persons && customer.persons.length > 0 && customer.persons[0].fiscality && customer.persons[0].fiscality) ? (customer.persons[0].fiscality.incomeTax) ? '1' : '0' : '0');

    if (getValues('fiscalitySubjectIfi') === undefined) {
      setValue('fiscalitySubjectIfi', '0');
    }

    if (customer.persons !== null && customer.persons[0]) {
      setValue('managerLastname', customer.persons[0].lastname);
      setValue('managerFirstname', customer.persons[0].firstname);
      setValue('managerPpe', customer.persons[0].ppe);
      setValue('managerPpeCategory', customer.persons[0].ppeCategory);
      setValue('managerBirthdate', customer.persons[0].birthdate);
      setValue('managerBirthCity', customer.persons[0].birthCity);
      setValue('managerExtranet', customer.persons[0].extranet ? '1' : '0');
      setValue('managerExtranetCreatedAt', customer.persons[0].extranetCreatedAt);
      setValue('optinMarketing', customer.persons[0].optinMarketing);
      setValue('optinDividend', customer.persons[0].optinDividend);
      setValue('optinFiscality', customer.persons[0].optinFiscality);
      setValue('optinGeneralAssembly', customer.persons[0].optinGeneralAssembly);
      setValue('optinInvitation', customer.persons[0].optinInvitation);

      setValue('personEmail', customer.persons[0].email);
      setValue('personPhone', customer.persons[0].phone);

      setValue('fiscality', customer.persons[0].fiscality);
      if (customer.persons[0].fiscality !== null && customer.persons[0].fiscality.incomeTax) {
        setValue('fiscalityIncomeTax', '1');
      } else {
        setValue('fiscalityIncomeTax', '0');
      }

      if (customer.persons[0].fiscality !== null && customer.persons[0].fiscality.businessTax) {
        setValue('fiscalityBusinessTaxt', '1');
      } else {
        setValue('fiscalityBusinessTaxt', '0');
      }

      if (customer.persons[0].fiscality !== null && customer.persons[0].fiscality.subjectIfi) {
        setValue('fiscalitySubjectIfi', '1');
      } else {
        setValue('fiscalitySubjectIfi', '0');
      }
    }


  }, [customer]);

  useEffect(() => {
    if (countryByDefault) {
      if (customer) {
        setValue('managerBirthCountry', (customer && customer.persons && customer.persons.length > 0 && customer.persons[0].birthCountry) ? customer.persons[0].birthCountry : countryByDefault[0]);
        setValue('managerNationality', (customer && customer.persons && customer.persons.length > 0 && customer.persons[0].nationality) ? customer.persons[0].nationality : countryByDefault[0]);
      } else {

        setValue('managerBirthCountry', countryByDefault[0]);
        setValue('managerNationality', countryByDefault[0]);
      }
    }
  }, [countryByDefault, customer]);

  useEffect( () => {
    if (customer && customer.id) {
      const documentGateway = new DocumentGateway(customer.id)
      documentGateway.getFiles().then((response) => {
        if (response) {
          setDocumentArray(response)
        }
      })

      documentGateway.getHistory().then((response) => {
        if (response) {
          setDocumentHistoryArray(response)
        }
      })
    }
  }, [openUploadForm])

  useEffect(() => {
    if (customer && customer.persons) {
      customer.persons.map((person) => {
        if (person.id && documentArray[person.id]) {
          documentArray[person.id].map((doc: DocumentInterface) => {
            setValue(`documents.${person.id}.${doc.type}.date`, doc.date)
            setValue(`documents.${person.id}.${doc.type}.updatedAt`, doc.updatedAt)
          })
        }
      })
    }
  }, [documentArray])

  const { register, control, watch, handleSubmit, reset, setValue, getValues } = useForm<IFormInput>();
  const watchStatus = watch('status')
  const watchLegalStatus = watch('legalStatus')
  const watchCompanyName = watch('companyName')
  const watchSiret = watch('siret')
  const watchManagerLastname = watch('managerLastname')
  const watchManagerFirstname = watch('managerFirstname')
  const watchManagerBirthdate = watch('managerBirthdate')
  const watchManagerNationality = watch('managerNationality')
  const watchManagerBirthCity = watch('managerBirthCity')
  const watchManagerBirthCountry = watch('managerBirthCountry')

  useEffect(() => {
    checkValidity()
  }, [
    watchStatus,
    watchLegalStatus,
    watchCompanyName,
    watchSiret,
    watchManagerLastname,
    watchManagerFirstname,
    watchManagerBirthdate,
    watchManagerNationality,
    watchManagerBirthCity,
    watchManagerBirthCountry
  ])

  const checkValidity = () => {
    const errors = []
    if (!watchStatus) {
      errors.push('account.form.status.status')
    }
    if (!watchLegalStatus) {
      errors.push('account.form.general-information.legalStatus')
    }
    if (!watchCompanyName) {
      errors.push('account.form.general-information.companyName')
    }
    if (!watchSiret) {
      errors.push('account.form.general-information.siret')
    }
    if (!watchManagerLastname) {
      errors.push('account.form.manager.managerLastname')
    }
    if (!watchManagerFirstname) {
      errors.push('account.form.manager.managerFirstname')
    }
    if (!watchManagerBirthdate) {
      errors.push('account.form.manager.managerBirthdate')
    }
    if (!watchManagerNationality) {
      errors.push('account.form.manager.managerNationality')
    }
    if (!watchManagerBirthCity) {
      errors.push('account.form.manager.managerBirthCity')
    }
    if (!watchManagerBirthCountry) {
      errors.push('account.form.general-information.personBirthCountry')
    }
    setFormErrors(errors);
  }

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (<ConfirmationModal onConfirm={ () => onConfirm(data) } onClose={ onClose }/>);
      },
    });
  };
  const onConfirm = (data: any) => {
    reset(data);
    if (null == customer.customerType) {
      customer.customerType = undefined !== customerType ? customerType : 'moral';
    }

    customer.customerCode = data.customerCode;
    customer.externalDepositaryCode = data.externalDepositaryCode;
    customer.externalCode = data.externalCode;
    customer.recoveryCode = data.recoveryCode;
    customer.moralType = data.moralType;
    customer.legalStatus = data.legalStatus;
    customer.companyName = data.companyName;
    customer.siret = data.siret;
    customer.siret = data.siret;

    if (customerStore !== null && customerStore.partner !== null && customerStore.partner.id !== null) {
      customer.partner = customerStore.partner;
    }

    let manager = new InitializePerson().initializePerson();

    if (customer.persons !== null && customer.persons[0]) {
      manager = customer.persons[0];
    }

    manager.gerant = true;
    manager.firstname = data.managerFirstname;
    manager.lastname = data.managerLastname;
    manager.ppe = data.managerPpe == '1';
    manager.ppeCategory = data.managerPpeCategory;
    manager.birthdate = data.managerBirthdate;
    manager.birthCity = data.managerBirthCity;
    manager.birthCountry = data.managerBirthCountry;
    manager.nationality = data.managerNationality;
    manager.extranet = data.managerExtranet == '1';
    manager.extranetCreatedAt = data.managerExtranetCreatedAt;
    manager.optinMarketing = data.optinMarketing;
    manager.optinDividend = data.optinDividend;
    manager.optinFiscality = data.optinFiscality;
    manager.optinGeneralAssembly = data.optinGeneralAssembly;
    manager.optinInvitation = data.optinInvitation;
    manager.documents = data.documents;
    manager.fiscality = data.fiscality;
    manager.email = data.personEmail;
    manager.phone = data.personPhone;

    if (manager.id && data.documents[manager.id]) {
      manager.documents = documentUtils.getDocumentsForPerson(data.documents[manager.id], documentArray, manager.id)
    }

    if (manager.fiscality) {
      if (data.fiscalityIncomeTax == '1') {
        manager.fiscality.incomeTax = true;
      } else {
        manager.fiscality.incomeTax = false;
      }

      if (data.fiscalityBusinessTaxt == '1') {
        manager.fiscality.businessTax = true;
      } else {
        manager.fiscality.businessTax = false;
      }

      if (data.fiscalitySubjectIfi == '1') {
        manager.fiscality.subjectIfi = true;
      } else {
        manager.fiscality.subjectIfi = false;
      }
    }

    if (customer.persons !== null && customer.persons[0]) {
      customer.persons[0] = manager;
    } else {
      customer.persons = [manager];
    }

    customer.kycCategoryValue = data.kycCategoryValue;
    customer.kycCategoryDate = data.kycCategoryDate;
    customer.kycLabft = data.kycLabft;
    customer.kycLabftDate = data.kycLabftDate;
    customer.kycProfession = data.kycProfession;
    customer.kycFatca = data.kycFatca;

    customer.status = data.status;
    customer.exitedAt = data.exitedAt;
    customer.blockedAt = data.blockedAt;

    setIsLoading(true)
    if (null !== customer.id) {
      new UpdateUseCase(new CustomerGateway())
        .execute(customer)
        .then(response => {
          setIsLoading(false)
          if (null !== response) {
            toastSuccess(t('account.notify.update-customer-success'));
            if(watchStatus) {
              setStatus(watchStatus)
            } else {
              setStatus('')
            }
          } else {
            toastError(t('account.notify.update-customer-error'));
          }
        })
        .catch(error => {
          toastError(t('account.notify.update-customer-error'));
        })
        .finally(() => {
          setIsLoading(false)
        });
    } else {
      new AddUseCase(new CustomerGateway())
        .execute(customer)
        .then(uuid => {
          setIsLoading(false)
          if (null !== uuid) {
            toastSuccess(t('account.notify.add-customer-success'));
            //window.location.href = `/${t('url.customer.edit-general-information')}/${uuid}`

          } else {
            toastError(t('account.notify.add-customer-error'));
          }
        })
        .catch(error => {
          toastError(t('account.notify.add-customer-error'));
        })
        .finally(() => {
          setIsLoading(false)
        });
    }
  };

  const { isDirty } = useFormState({
    control,
  });
  usePrompt(isLectureMode ? false : isDirty, handleSubmit(onConfirm));

  const watchOptinAll = watch('optinAll.type');

  useEffect(() => {
    if (watchOptinAll) {
      setValue('optinMarketing.type', watchOptinAll);
      setValue('optinFiscality.type', watchOptinAll);
      setValue('optinInvitation.type', watchOptinAll);
      setValue('optinGeneralAssembly.type', watchOptinAll);
      setValue('optinDividend.type', watchOptinAll);
    }
  }, [watchOptinAll]);

  const watchManagerPpe = watch('managerPpe');
  useEffect(() => {
    if (!watchManagerPpe) {
      setValue('managerPpeCategory', '');
    }
  }, [watchManagerPpe]);

  const watchMoralType = watch('moralType');
  useEffect(() => {
    if (customer) {
      customer.moralType = getValues('moralType');
      if (handler) {
        handler(customer);
      }
    }
  }, [watchMoralType]);

  function isTypeDisabled(): boolean {
    if (customer) {
      if (customer.undivideds && watchMoralType === 'joint_possession') {
        return customer.undivideds.length > 0;
      }
      if (customer.beneficialOwners && watchMoralType === 'business') {
        return customer.beneficialOwners.length > 0;
      }
      if (customer.moralLegalRepresentatives && watchMoralType === 'other') {
        return customer.moralLegalRepresentatives.length > 0;
      }
    }
    return false;
  }

  const immatriculationBlock = <div className={ `form-bloc form--bloc--immatriculation` }>
    <div className="form-bloc__title">{ t('account.form.immatriculation.title') }</div>
    <div className="form-bloc__form flex-container">
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="customerCode" className="form-control__label">{ t('account.form.immatriculation.customerCode') }</label>
          <div className="form-control__input">
            <input type="text" id="customerCode" { ...register('customerCode') } disabled={ true }/>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="externalDepositaryCode"
                 className="form-control__label">{ t('account.form.immatriculation.externalDepositaryCode') }</label>
          <div className="form-control__input">
            <input type="text" id="externalDepositaryCode" { ...register('externalDepositaryCode') } disabled={ true }/>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="externalCode" className="form-control__label">{ t('account.form.immatriculation.externalCode') }</label>
          <div className="form-control__input">
            <input type="text" id="externalCode" { ...register('externalCode') } disabled={ true }/>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="recoveryCode" className="form-control__label">{ t('account.form.immatriculation.recoveryCode') }</label>
          <div className="form-control__input">
            <input type="text" id="recoveryCode" { ...register('recoveryCode') } disabled={ true }/>
          </div>
        </div>
      </div>
    </div>
  </div>;
  const generalInformationBlock = <div className={ `form-bloc form--bloc--general-information` }>
    <div className="form-bloc__title">{ t('account.form.general-information.title') }</div>
    <div className="form-bloc__form flex-container">
      <div className="col-md-6">
        <div className="form-control">
          <label htmlFor="moralType" className="form-control__label">{ t('account.form.general-information.moralType') }</label>
          <div className="form-control__input">
            <select { ...register('moralType') } disabled={ isTypeDisabled() }>
              { referential && referential.prospect.moral_type.map((item, index) => {
                return ( <option key={ index } value={item.value}> {item.label} </option> )
              })}
            </select>
          </div>
        </div>
      </div>
      <SelectCustom classes="col-md-6"
                    valueClasses={ !watchLegalStatus ? "field-in-error" : "" }
                    id="legalStatus"
                    name="legalStatus"
                    options={ referential.global.title_moral }
                    register={ register }
                    label={ t('account.form.general-information.legalStatus') }
                    labelClasses="mendatory-field"
      />
      <div className="col-md-6">
        <div className="form-control">
          <label htmlFor="recoveryCode" className="form-control__label mendatory-field">{ t('account.form.general-information.companyName') }</label>
          <div className="form-control__input">
            <input type="text" id="recoveryCode" { ...register('companyName') } className={!watchCompanyName ? 'field-in-error' : ''}/>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-control">
          <label htmlFor="recoveryCode" className="form-control__label mendatory-field">{ t('account.form.general-information.siret') }</label>
          <div className="form-control__input">
            <input type="text" id="recoveryCode" { ...register('siret') } className={!watchSiret ? 'field-in-error' : ''} />
          </div>
        </div>
      </div>
    </div>
  </div>;
  const managerBlock = <div className={ `form-bloc form--bloc--general-information` }>
    <div className="form-bloc__title">{ t('account.form.manager.title') }</div>
    <div className="flex-container">
      <div className="col-md-12">
        <div className="form-bloc__form flex-container">
          <div className="col-md-6">
            <div className="form-control">
              <label htmlFor="personFirstBirthdate" className="form-control__label mendatory-field">{ t('account.form.manager.managerLastname') }</label>
              <div className="form-control__input">
                <input type="text" { ...register('managerLastname') } className={!watchManagerLastname ? 'field-in-error' : ''}/>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-control">
              <label htmlFor="personFirstBirthdate" className="form-control__label mendatory-field">{ t('account.form.manager.managerBirthdate') }</label>
              <div className="form-control__input">
                <InputDate register={ register }
                           control={ control }
                           classes={!watchManagerBirthdate ? 'field-in-error' : ''}
                           name="managerBirthdate"
                           id="managerBirthdate"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-control">
              <label htmlFor="personFirstBirthCity" className="form-control__label mendatory-field">{ t('account.form.manager.managerFirstname') }</label>
              <div className="form-control__input">
                <input type="text" { ...register('managerFirstname') } className={!watchManagerFirstname ? 'field-in-error' : ''}/>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <CityDatalist setValue={ setValue }
                          classes={!watchManagerBirthCity ? 'field-in-error' : ''}
                          control={ control }
                          name={ 'managerBirthCity' }
                          labelContent={ t('account.form.manager.managerBirthCity') }
                          mode={ 'row' }
                          country={ getValues('managerBirthCountry') }
                          defaultValue={ getValues('managerBirthCity') }
                          disabled={isLectureMode}
            />

          </div>
          <div className="col-md-3">
            <div className="form-control">
              <label htmlFor="personFirstBirthCity" className="form-control__label mendatory-field">{ t('account.form.manager.managerNationality') }</label>
              <div className="form-control__input">
                <CountryDatalist control={ control } isOnError={!watchManagerNationality} name="managerNationality" defaultValue={ getValues('managerNationality') }
                                 disabled={ isLectureMode }/>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <CheckboxToggle id={'managerPpe'} name={'managerPpe'} register={register} label={t('account.form.manager.managerPpe')} />
          </div>
          <div className="col-md-3">
            {watchManagerPpe &&
              <SelectCustom classes="" id="managerPpeCategory" name="managerPpeCategory"
                            options={ referential.customer.ppe_category } register={ register }
                            label={ t('account.form.manager.managerPpeCategory') }/>
            }
          </div>
          <div className="col-md-6">
            <div className="form-control">
              <label htmlFor="managerBirthCountry"
                     className="form-control__label mendatory-field">{ t('account.form.general-information.personBirthCountry') }</label>
              <div className="form-control__input">
                <CountryDatalist control={ control }isOnError={!watchManagerBirthCountry}
                  name="managerBirthCountry" defaultValue={ getValues('managerBirthCountry') }
                                 disabled={ isLectureMode }/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
  const kycBlock = <div className={ `form-bloc form--bloc--kyc` }>
    <div className="form-bloc__title">{ t('account.form.kyc.title') }</div>
    <div className="form-bloc__form flex-container">
      <div className="col-md-6">
        <div className="form-control">
          <label htmlFor="kycCategoryValue" className="form-control__label">{ t('account.form.kyc.kycCategoryValue') }</label>
          <div className="form-control__input">
            <select { ...register('kycCategoryValue') }>
              <option value=""/>
              { referential.prospect.kyc_category_value.map((item, index) => <option key={ index }
                                                                                           value={ item.value }>{ item.label }</option>) }
            </select>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-control">
          <label htmlFor="kycCategoryDate" className="form-control__label">{ t('account.form.kyc.kycCategoryDate') }</label>
          <div className="form-control__input">
            <InputDate id="kycCategoryDate"
                       name="kycCategoryDate"
                       register={ register }
                       control={ control }
            />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-control">
          <label htmlFor="kycLabft" className="form-control__label">{ t('account.form.kyc.kycLabft') }</label>
          <div className="form-control__input">
            <select { ...register('kycLabft') }>
              <option value=""/>
              { referential.prospect.kyc_labft.map((item, index) => <option key={ index }
                                                                                  value={ item.value }>{ item.label }</option>) }
            </select>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-control">
          <label htmlFor="kycLabftDate" className="form-control__label">{ t('account.form.kyc.kycLabftDate') }</label>
          <div className="form-control__input">
            <InputDate
              id="kycLabftDate"
              name="kycLabftDate"
              register={ register }
              control={ control }
            />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-control">
          <label htmlFor="kycProfession" className="form-control__label">{ t('account.form.kyc.kycProfession') }</label>
          <div className="form-control__input">
            <select { ...register('kycProfession') }>
              <option value=""/>
              { referential.global.profession.map((item, index) => <option key={ index }
                                                                                 value={ item.value }>{ item.label }</option>) }
            </select>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <Checkbox id={'kycFatca'} name={'kycFatca'} register={register} label={t('account.form.kyc.kycFatca')} align={'normal'}/>
      </div>
    </div>
  </div>;
  const fiscalityBlock = <div className={ `form-bloc form--bloc--fiscality` }>
    <div className="form-bloc__title">{ t('account.form.fiscality.title') }</div>
    <div className="form-bloc__form flex-container">
      <div className="col-md-12">
        <div className="form-control">
          <label htmlFor="personFirstFiscalityPaymentMethod"
                 className="form-control__label">{ t('account.form.fiscality.paymentMethod') }</label>
          <div className="form-control__input">
            <select { ...register('fiscality.paymentMethod') }>
              <option value=""/>
              { referential.prospect.payment_method.map((item, index) => <option key={ index }
                                                                                       value={ item.value }>{ item.label }</option>) }
            </select>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-control">
          <label htmlFor="personFirstFiscalityTaxResidency"
                 className="form-control__label">{ t('account.form.fiscality.taxResidency') }</label>
          <div className="form-control__input">
            <select { ...register('fiscality.taxResidency') } id="personFirstFiscalityTaxResidency">
              <option value=""/>
              { referential.prospect.tax_residency.map((item, index) => <option key={ index }
                                                                                      value={ item.value }>{ item.label }</option>) }
            </select>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-control">
          <label htmlFor="personFirstFiscalityRate" className="form-control__label">{ t('account.form.fiscality.rate') }</label>
          <div className="form-control__input">
            <select { ...register('fiscality.rate') } id="personFirstFiscalityRate">
              <option value=""/>
              { referential.prospect.nr_rate.map((item, index) => <option key={ index }
                                                                                value={ item.value }>{ item.label }</option>) }
            </select>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <RadioYesOrNot id={'fiscalityBusinessTaxt'} name={'fiscalityBusinessTaxt'} register={register} label={t('account.form.fiscality.businessTax')}/>
      </div>
      <div className="col-md-12">
        <RadioYesOrNot id={'fiscalityIncomeTax'} name={'fiscalityIncomeTax'} register={register} label={t('account.form.fiscality.incomeTax')}/>
      </div>

      <div className="col-md-12">
        <RadioYesOrNot id={'fiscalitySubjectIfi'} name={'fiscalitySubjectIfi'} register={register} label={t('account.form.fiscality.subjectIfi')}/>
      </div>

      <div className="col-md-12">
        <div className="form-control">
          <label htmlFor="ifi" className="form-control__label">{ t('account.form.fiscality.ifi') }</label>
          <div className="form-control__input">
            <input type="text" id="ifi" { ...register('fiscality.ifi') }/>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="form-control">
          <label htmlFor="lei" className="form-control__label">{ t('account.form.fiscality.lei') }</label>
          <div className="form-control__input">
            <input type="text" id="lei" { ...register('fiscality.lei') }/>
          </div>
        </div>
      </div>
    </div>
  </div>;
  const communicationBlock = <div className={ `form-bloc form--bloc--communication` }>
    <div className="form-bloc__title">{ t('account.form.communication.title') }</div>
    <div className="form-bloc__form flex-container">
      <div className="col-md-3">
        <RadioYesOrNot id={'managerExtranet'} name={'managerExtranet'} register={register} label={t('account.form.communication.personExtranet')}/>
      </div>
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="lei" className="form-control__label">{ t('account.form.communication.personExtranetCreatedAt') }</label>
          <div className="form-control__input">
            <InputDate
              id="statusCreatedAt"
              name="managerExtranetCreatedAt"
              register={ register }
              control={ control }
            />
          </div>
        </div>
      </div>
      <div className="col-md-3"/>
      <div className="col-md-3"/>
      <Input register={ register }
             classes={ 'col-md-6' }
             label={ t('account.form.communication.personEmail') }
             type={ 'text' }
             name={ 'personEmail' }
             id={ 'personEmail' }
      />
      <Input register={ register }
             classes={ 'col-md-6' }
             label={ t('account.form.communication.personPhone') }
             type={ 'text' }
             name={ 'personPhone' }
             id={ 'personPhone' }
      />
      <div className="col-md-12">
      </div>
      <div className="col-md-6">
        <table className="table--communication">
          <thead>
          <tr>
            <th/>
            {referential.prospect.communication_type.map((communication_type) => {
              return (
                <th key={ 'table' + uuidV4() } className="form-control__label">{ communication_type.label }</th>
              );
            }) }
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <label className="form-control__label">{ t('account.form.communication.optinMarketing') }</label>
            </td>
            {referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={ uuidV4() }>
                  <div className="form-control__input">
                    <input type="radio" value={ communication_type.value } { ...register('optinMarketing.type') }
                           disabled={ isLectureMode }/>
                  </div>
                </td>
              );
            }) }
          </tr>
          <tr>
            <td>
              <label className="form-control__label">{ t('account.form.communication.optinDividend') }</label>
            </td>
            {referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={ uuidV4() }>
                  <div className="form-control__input">
                    <input type="radio" value={ communication_type.value } { ...register('optinDividend.type') }
                           disabled={ isLectureMode }/>
                  </div>
                </td>
              );
            }) }
          </tr>
          <tr>
            <td>
              <label className="form-control__label">{ t('account.form.communication.optinFiscality') }</label>
            </td>
            {referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={ uuidV4() }>
                  <div className="form-control__input">
                    <input type="radio" value={ communication_type.value } { ...register('optinFiscality.type') }
                           disabled={ isLectureMode }/>
                  </div>
                </td>
              );
            }) }
          </tr>
          <tr>
            <td>
              <label className="form-control__label">{ t('account.form.communication.optinGeneralAssembly') }</label>
            </td>
            {referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={ uuidV4() }>
                  <div className="form-control__input">
                    <input type="radio" value={ communication_type.value } { ...register('optinGeneralAssembly.type') }
                           disabled={ isLectureMode }/>
                  </div>
                </td>
              );
            }) }
          </tr>
          <tr>
            <td>
              <label className="form-control__label">{ t('account.form.communication.optinInvitation') }</label>
            </td>
            {referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={ uuidV4() }>
                  <div className="form-control__input">
                    <input type="radio" value={ communication_type.value } { ...register('optinInvitation.type') }
                           disabled={ isLectureMode }/>
                  </div>
                </td>
              );
            }) }
          </tr>
          <tr>
            <td>
              <label className="form-control__label">{ t('account.form.communication.optinAll') }</label>
            </td>
            {referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={ uuidV4() }>
                  <div className="form-control__input">
                    <input type="radio" value={ communication_type.value } { ...register('optinAll.type') } disabled={ isLectureMode }/>
                  </div>
                </td>
              );
            }) }
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>;
  const documentsBlock = customer?.persons?.map((person, index) => {
    if (customer && customer.id && person && person.id) {
      return (
        <>
          <div className="col-md-6">
            <DocumentForm
              title={`${t('movement.pre-subscription.form.list-of-documents.title')} : ${person.lastname + ' ' + person.firstname}`}
              name={`documents.${person.id}`}
              uuid={person.id}
              documentGateway={new DocumentGateway(customer.id)}
              register={register}
              control={control}
              documentData={documentArray[person.id]}
              isLectureMode={isLectureMode}
            />
          </div>
        </>
      )
    }
  })
  const statusBlock = <div className={ `form-bloc form--bloc--status` }>
    <div className="form-bloc__title">{ t('account.form.status.title') }</div>
    <div className="form-bloc__form flex-container">
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="statusCreatedAt" className="form-control__label">{ t('account.form.status.statusCreatedAt') }</label>
          <div className="form-control__input">
            <InputDate id="statusCreatedAt"
                       name="statusCreatedAt"
                       register={ register }
                       control={ control }
                       disabled={true}
            />
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="status" className="form-control__label mendatory-field">{ t('account.form.status.status') }</label>
          <div className="form-control__input">
            <select { ...register('status') } className={!watchStatus ? 'field-in-error' : ''}>
              <option value=""/>
              {referential.prospect.status.map((item, index) => <option key={ index } value={ item.value }>{ item.label }</option>)}
            </select>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="exitedAt" className="form-control__label">{ t('account.form.status.exitedAt') }</label>
          <div className="form-control__input">
            <InputDate id="exitedAt"
                       name="exitedAt"
                       register={ register }
                       control={ control }
            />
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="blockedAt" className="form-control__label">{ t('account.form.status.blockedAt') }</label>
          <div className="form-control__input">
            <InputDate id="blockedAt"
                       name="blockedAt"
                       register={ register }
                       control={ control }
            />
          </div>
        </div>
      </div>
    </div>
  </div>;

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node);
    }
  }, []);

  return (
    <form onSubmit={ handleSubmit(onSubmit) } ref={ measuredRef }>
      <div className="flex-container">
        <div className="col-md-12">
          {formErrors.length ? <FormErrorInfo errors={formErrors} message={t('account.notify.form-error-customer-base-message')}/> : null}
        </div>
      <div className="col-md-12">{ immatriculationBlock }</div>
      <div className="col-md-12">{ generalInformationBlock }</div>
      <div className="col-md-12">{ managerBlock }</div>
      <div className="col-md-6">{ fiscalityBlock }</div>
      <div className="col-md-6">{ kycBlock }</div>
      <div className="col-md-12">{ communicationBlock }</div>
      <div className="col-md-12">{ documentsBlock }</div>
      <div className="col-md-12">
        {customer && customer.id && customer.recoveryCode && documentHistoryArray && (documentHistoryArray.length > 0) &&
        <div className="form-bloc">
          <DocumentHistoryBlock documents={documentHistoryArray} gateway={new DocumentGateway(customer.id)}/>
        </div>
        }
      </div>
      <div className="col-md-12">{ statusBlock }</div>
      </div>
      {!isLectureMode && <FooterBlock disabled={isLoading} onCancelUrl={t('url.customer.dashboard')} />}
    </form>
  );
};

export default GeneralInformationMoralPerson

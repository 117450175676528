import {FC} from 'react'

interface IFieldErrorMessageProps {
    message: string
}

const FieldErrorMessage: FC<IFieldErrorMessageProps> = ({message}) => {
    return (
        <span className={'help help-error'}>{message}</span>
    )
};

export default FieldErrorMessage;

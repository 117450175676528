import Caller from "../../domain/Api/Caller";
import envVariable from "../../infrastructure/presentation/util/envVariable";
import RecurrenceMandateGatewayInterface from "../../domain/Recurrence/RecurrenceMandateGatewayInterface";
import { FilterRecurrenceMandateInterface, RecurrenceMandateListInterface } from "../../domain/Recurrence/RecurrenceMandateList";
import {EditRecurrenceMandateInterface, RecurrenceMandateInterface} from '../../domain/Recurrence/RecurrenceMandate'
import dayjs from 'dayjs'

export default class RecurrenceMandateGateway implements RecurrenceMandateGatewayInterface {
  getList(
    currentPage: number,
    itemsPerPage: number,
    filter: FilterRecurrenceMandateInterface|null,
    signal?: AbortSignal,
    sort?: string,
    order?: string
  ): Promise<{data: RecurrenceMandateListInterface[]|null, numberOfItems: number}|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/recurrences/mandates?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${sort ? '&sort=' + sort + '&order=' + order : ''}`, {
      product_acronyme: filter?.product.label || '',
      status: filter?.status.map(it => it.value) || '',
      periodicity: filter?.periodicity.map(it => it.value) || '',
      start: filter?.start || '',
      end: filter?.end || '',
      keyword: filter?.keyword || ''
    },
    signal
  ).then(data => {
    if (data) {
      return data
    }

    return {
      data: [],
      numberOfItems: 0
    }
  })
  .catch(() => {
    return {
      data: [],
      numberOfItems: 0
    }
  });
}

  getExportAsBlob(filter: FilterRecurrenceMandateInterface|null): Promise<Blob|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/recurrences/mandates/extract`, {
      product_acronyme: filter?.product.label || '',
      status: filter?.status.map(it => it.value) || '',
      periodicity: filter?.periodicity.map(it => it.value) || '',
      keyword: filter?.keyword || ''
    })
    .then(data => {
      if (data instanceof Blob) {
        return data
      }

      return null
    })
    .catch(() => {
      return null
    })
  }
  async get(uuid: string): Promise<RecurrenceMandateInterface | null> {
    try {
      const data = await Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/recurrences/mandates/${uuid}`, {})
      if (data) {
        return data
      }
      return null
    } catch (e) {
      return null
    }
  }
  async update(data: EditRecurrenceMandateInterface, uuid: string): Promise<RecurrenceMandateInterface | null> {
    console.log(data)
    const params = {
      status: data.status,
      start_at: dayjs(data.start, 'DD/MM/YYYY').format('YYYY-MM-DD 00:00:00'),
      end_at: dayjs(data.end, 'DD/MM/YYYY').format('YYYY-MM-DD 00:00:00'),
      periodicity: data.periodicity,
      rum: data.rum,
      rum_status: data.rumStatus,
      recurrence_share: data.shareCount,
    }
    try {
      const data = await Caller.executePut(`${envVariable('REACT_APP_API_URL')}/recurrences/mandates/${uuid}`, {...params})
      if (data) {
        return data
      }
      return null
    } catch (e) {
      return null
    }
  }
}

import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SubmitHandler, useForm, useFormState} from 'react-hook-form';
import {v4 as uuidV4} from 'uuid';

import {useAppDispatch, useAppSelector} from '../../../../../store/hook';
import {
  setOpenCommissionRelationForm,
} from '../../../../../store/component/event';

import {
  deleteCommissionRelation,
  reloadPartner,
  setCommissionRelations
} from '../../../../../store/component/partner'
import {ConventionAnnexInterface} from '../../../../../../domain/Partner/ConventionAnnex';
import {PartnerInterface} from '../../../../../../domain/Partner/Partner';
import UpdateUseCase from '../../../../../../useCase/Partner/Update/UpdateUseCase';
import PartnerGateway from '../../../../../../gateway/Partner/PartnerGateway';
import AddUseCase from '../../../../../../useCase/Partner/Add/AddUseCase';

import iconAdd from '../../../../../../assets/images/icons/add.svg';
import iconRemove from '../../../../../../assets/images/icons/remove.svg';

import Input from '../../../Elements/Input';
import {confirmAlert} from 'react-confirm-alert';
import ConfirmationModal from '../../../Modal/ConfirmationModal';
import {toastError, toastSuccess} from '../../../../util/Toast';
import {usePrompt} from '../../../../util/Navigation';
import {setReadOnlyFormElements} from '../../../../util/setReadOnlyFormElements';
import InputDate from "../../../Elements/InputDate";
import SelectCustom from "../../../Elements/Select";
import {
  ReferentielInterface
} from '../../../../../../domain/Referentiel/ReferentielInterface'
import CheckboxToggle from "../../../Elements/CheckboxToggle";
import Iban from "../../../Elements/Iban";
import Bic from "../../../Elements/Bic";
import RadioYesOrNot from '../../../Elements/RadioYesOrNot';
import FormErrorInfo from "../../../Alert/FormErrorInfo/FormErrorInfo";
import {ScaleItemInterface} from "../../../../../../domain/Product/ScaleItem";
import {stringToDate} from "../../../../../transformer/StringDateToDateObject/StringDateToDateObject";
import FooterBlock from "../../../Block/FooterBlock";

interface IFormInput {
  intraVatNumber: string|null
  accountingAccount: string|null
  invoiceMandate: string|null
  invoiceMandateValidationDate: string|null
  vatLiable: string|null
  bankName: string|null
  ibanCode: string|null
  swiftCode: string|null
  paymentMode: string|null
  ownerName: string|null
  commissioned: boolean
  hasCommissionRelation: boolean
}
type Props = {
  referential: ReferentielInterface
  partner?: PartnerInterface
  isLectureMode?: boolean
}
const Commissions: FunctionComponent<Props> = ({partner, isLectureMode, referential}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()


  const conventionAnnexArray = useAppSelector((state) => state.partner.conventionAnnexArray)
  const commissionRelations = useAppSelector((state) => state.partner.commissionRelations)
  const [maxPartnerCommissionedLevel, setMaxPartnerCommissionedLevel] = useState(partner?.commissionLevel || "niveau_1")

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [formErrors, setFormErrors] = useState<string[]>([])

  const { register, control, handleSubmit, reset, setValue, watch, formState: { errors, isValid} } = useForm<IFormInput>();

  useEffect(() => {
    if (partner) {
      setValue("accountingAccount", partner.accountingAccount)
      setValue("bankName", partner.bankName)
      setValue("ibanCode", partner.iban)
      setValue("swiftCode", partner.swift)
      setValue("paymentMode", partner.paymentMode)
      setValue("intraVatNumber", partner.tvaNumber)
      setValue("invoiceMandate", partner.billingMandate ? "1": "0")
      setValue("invoiceMandateValidationDate", partner.billingMandateValidatedAt)
      setValue("ownerName", partner.ownerName)
      setValue("commissioned", partner.commissioned)
      setValue("hasCommissionRelation", partner.hasCommissionRelation)
      setValue("vatLiable", partner.tvaSubject ? '1' : '0')

      if(partner.commissionRelations) {
        const parseCommissionRelations = partner.commissionRelations.map((rel: any) => {
          return {
            ...rel.relation,
            id: rel.relation.id,
            partnerCode: rel.relation.partnerCode,
            socialReason: rel.relation.socialReason,
            commissionLevel: rel.relation.commissionLevel
          }
        })

        let maxLevelPartnerCommissioned = partner?.commissionLevel || "niveau_1"

        parseCommissionRelations.forEach((rel) => {
          if(rel.commission_level > maxLevelPartnerCommissioned) {
            maxLevelPartnerCommissioned = rel.commission_level
          }
        })
        dispatch(setCommissionRelations(parseCommissionRelations))
        setMaxPartnerCommissionedLevel(maxLevelPartnerCommissioned)
      } else {
        setMaxPartnerCommissionedLevel(partner?.commissionLevel || "niveau_1")
      }
    }
    setIsLoading(false)
  }, [partner])

  useEffect(() => {
    if(commissionRelations.length) {
      let maxLevelPartnerCommissioned = partner?.commissionLevel || "niveau_1"
      commissionRelations.forEach((rel) => {
        if (!rel.commissionLevel) return
        else if (rel?.commissionLevel > maxLevelPartnerCommissioned) {
          maxLevelPartnerCommissioned = rel.commissionLevel
        }
      })
      setMaxPartnerCommissionedLevel(maxLevelPartnerCommissioned)
    }
    else setMaxPartnerCommissionedLevel(partner?.commissionLevel || "niveau_1")

  }, [commissionRelations])

  const watchBankName = watch('bankName')
  const watchOwnerName = watch('ownerName')
  const watchIbanCode = watch('ibanCode')
  const watchSwiftCode = watch('swiftCode')
  const watchPaymentMode = watch('paymentMode')

  useEffect(() => {
    checkValidity()
  }, [watchOwnerName, watchBankName, watchIbanCode, watchSwiftCode, watchPaymentMode, conventionAnnexArray])

  const checkValidity = () => {
    const errors = []
    if (!watchOwnerName) {
      errors.push('partner.form.accounting.owner-name')
    }
    if (!watchBankName) {
      errors.push('partner.form.accounting.bank-name')
    }
    if (!watchIbanCode) {
      errors.push('partner.form.accounting.iban-code')
    }
    if (!watchSwiftCode) {
      errors.push('partner.form.accounting.swift-code')
    }
    if (!watchPaymentMode) {
      errors.push('partner.form.accounting.payment-mode')
    }
    if (conventionAnnexArray) {
      const isItemDateNotOK = (scale: ScaleItemInterface) => {
        const dateEnd = stringToDate(scale?.period?.end)
        if (dateEnd) return dateEnd < new Date()
        return true
      }
      const isConventionNotOK = (conv: ConventionAnnexInterface) => {
        if (conv.scaleItems.length > 0) return conv.scaleItems.filter(isItemDateNotOK).length > 0;
        return true
      }
      if (conventionAnnexArray.filter(isConventionNotOK).length > 0) {
        errors.push('Barème lié à une convention')
      }
    }
    setFormErrors([...errors]);
  }


  const onSubmit: SubmitHandler<IFormInput> = data => {
    if (!isValid) {
      return
    }
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    reset(data)
    if (partner) {
      partner.accountingAccount = data.accountingAccount
      partner.bankName = data.bankName
      partner.iban = data.ibanCode
      partner.swift = data.swiftCode
      partner.paymentMode = data.paymentMode
      partner.tvaNumber = data.intraVatNumber
      partner.billingMandate = data.invoiceMandate === "1"
      partner.billingMandateValidatedAt = data.invoiceMandateValidationDate
      partner.tvaSubject = data.vatLiable === "1"
      partner.ownerName = data.ownerName
      partner.hasCommissionRelation = data.hasCommissionRelation
      partner.commissioned = data.commissioned


      if(partner.hasCommissionRelation) {
        partner.commissionRelations = commissionRelations
      } else {
        partner.commissionRelations = []
      }

      setIsLoading(true)
      if (partner.id !== null) {
        new UpdateUseCase(new PartnerGateway()).execute(partner).then(response => {
          if (null !== response) {
            toastSuccess(t('partner.notify.update-success'))
            dispatch(reloadPartner());
          } else {
            toastError(t('partner.notify.update-error'));
          }
        })
      }
    }
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isLectureMode ? false : isDirty, handleSubmit(onConfirm));

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, []);

  const hasCommissionRelation = watch('hasCommissionRelation')

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
      <div className="col-md-12">
        {formErrors.length > 0 && <FormErrorInfo errors={formErrors}  message={t('account.notify.form-error-base-message')}/>}
      </div>
      <div className={`form-bloc`}>
        <div className="form-bloc__title">{t('partner.form.accounting.title')}</div>
        <div className="form-bloc__form flex-container">
          <div className="col-md-6">
            <div className="form-control">
              <label htmlFor="intraVatNumber" className="form-control__label">{t('partner.form.accounting.intra-vat-number')}</label>
              <div className="form-control__input">
                <input type="text" {...register('intraVatNumber')} />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-control">
              <label htmlFor="accountingAccount" className="form-control__label">{t('partner.form.accounting.accounting-account')}</label>
              <div className="form-control__input">
                <input type="text" {...register('accountingAccount')} />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <RadioYesOrNot id={'invoiceMandate'} name={'invoiceMandate'} register={register} label={t('partner.form.accounting.invoice-mandate')}/>
            {/* <div className="form-control"> */}
            {/*   <label htmlFor="accountingAccount" className="form-control__label">{t('partner.form.accounting.invoice-mandate')}</label> */}
            {/*   <div className="form-control form-control--label-auto"> */}
            {/*     <div className="form-control__input"> */}
            {/*       <label className="form-label form-label--input-radio"> */}
            {/*         <input type="radio" value="1" {...register('invoiceMandate')} /> */}
            {/*         {t('common.yes')} */}
            {/*       </label> */}
            {/*     </div> */}
            {/*     <div className="form-control__input"> */}
            {/*       <label className="form-label form-label--input-radio"> */}
            {/*         <input type="radio" value="0" {...register('invoiceMandate')} /> */}
            {/*         {t('common.no')} */}
            {/*       </label> */}
            {/*     </div> */}
            {/*   </div> */}
            {/* </div> */}
          </div>
          <div className="col-md-3">
            <div className="form-control">
              <label htmlFor="invoiceMandateValidationDate" className="form-control__label">{t('partner.form.accounting.invoice-mandate-validation-date')}</label>
              <div className="form-control__input">
                <InputDate id="invoiceMandateValidationDate"
                           name="invoiceMandateValidationDate"
                           control={control}
                           register={register}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <RadioYesOrNot id={'vatLiable'} name={'vatLiable'} register={register} label={t('partner.form.accounting.vat-liable')}/>
          </div>
          <div className="col-md-12">
            <div className="u-txt-size-s">{t('partner.form.accounting.banking-information')}</div>
          </div>
          <Input id="ownerName" name="ownerName" register={register} inputClasses={watchOwnerName ? "" : "field-in-error"}
                 classes="col-md-6 mandatory-field" label={t('partner.form.accounting.owner-name')} />
          <div className="col-md-6"/>
          <div className="col-md-6">
            <div className="form-control">
              <label htmlFor="bankName" className="form-control__label mandatory-field">{t('partner.form.accounting.bank-name')}</label>
              <div className={watchBankName ? "form-control__input" : "form-control__input field-in-error"}>
                <input type="text" {...register('bankName')} />
              </div>
            </div>
          </div>
          <div className="col-md-6"/>
          <div className="col-md-6">
            <Iban
              label={t('partner.form.accounting.iban-code')}
              id={'ibanCode'}
              required={false}
              name={'ibanCode'}
              error={errors?.ibanCode}
              register={register}
              enableValidation={true}
            />
          </div>
          <div className="col-md-6">
            <Bic
              label={t('partner.form.accounting.swift-code')}
              id={'swiftCode'}
              required={false}
              name={'swiftCode'}
              error={errors?.swiftCode}
              register={register}
              enableValidation={true}
            />
          </div>
          <div className="col-md-6">
            <SelectCustom classes="mandatory-field"
                          valueClasses={watchPaymentMode ? "" : "field-in-error"}
                          id="paymentMode"
                          name="paymentMode"
                          label={t('partner.form.accounting.payment-mode')}
                          options={referential?.partner['payment_mode']}
                          register={register}
                          noChoiceOption={false}
            />
          </div>
        </div>
      </div>
      <div className={`form-bloc`}>
        <div className="form-bloc__title">{t('partner.form.commission.title')}</div>
        <div className="form-bloc__form flex-container" key={uuidV4()}>
          <div className="col-md-6">
              <div className="form-control--label-max-content flex items-center u-mb0">
                <label htmlFor="acronym"
                       className="form-control__label u-mrm">{t('partner.form.commission.partner-level')}</label>
                <div className="form-control__input">
                  <input type="text"
                         disabled={true}
                         value={`${t("partner.form.commission.level", {
                           level: partner?.commissionLevel?.replace("niveau_","") || "1"
                         })}`}
                  />
                </div>
              </div>
          </div>
          <CheckboxToggle id="commissioned"
                          name="commissioned"
                          register={register}
                          label={t('partner.form.commission.partner-commission')}
                          classes="col-md-6"
                          classesLabel="form-control--label-max-content"
          />
          <CheckboxToggle id="hasCommissionRelation"
                          name="hasCommissionRelation"
                          register={register}
                          label={t('partner.form.commission.partner-other-relation')}
                          classes="col-md-12"
                          classesLabel="form-control--label-max-content"
          />
          { hasCommissionRelation &&
            <>
              <div className="col-md-12">
                <button type="button"
                        className="button-reset u-txt-with-icon"
                        onClick={() => dispatch(setOpenCommissionRelationForm({show: true, level: maxPartnerCommissionedLevel, currentLevel: partner?.commissionLevel }))}
                >
                  <img src={iconAdd} alt=""/>
                  {t('partner.form.commission.add-relation')}
                </button>
              </div>
              <div className="col-md-12">
                {commissionRelations.map((rel) => {
                  return <div className="form-bloc__form flex-container" key={uuidV4()}>
                    <div className="col-md-12">
                      <div className="form-resume">
                        <div className="form-control form-control--center u-mb0">
                          <label htmlFor="partnerNum" className="form-control__label">{t('partner.form.bloc-general-information.partner-num')}</label>
                          <div className="form-control__input">
                            <input type="text" disabled={true} value={`${rel.partnerCode}`} />
                          </div>
                        </div>
                        <div className="form-control form-control--center u-mb0">
                          <label htmlFor="partnerNum" className="form-control__label">{t('partner.form.bloc-general-information.social-reason')}</label>
                          <div className="form-control__input">
                            <input type="text" disabled={true} value={`${rel?.socialReason}`} />
                          </div>
                        </div>
                        <div className="form-control form-control--center u-mb0">
                          <label htmlFor="legalStatus" className="form-control__label">{t('partner.form.commission.reglement_retro')}</label>
                          <div className="form-control__input">
                            <input type="text"
                                   disabled={true}
                                   value={`${t("partner.form.commission.level", {
                                     level: rel?.commissionLevel?.replace("niveau_","") || "1"
                                   })}`}
                            />
                          </div>
                        </div>

                        <div className="form-control form-control--center u-mb0">
                          <div className={`checkbox-toggle__wrapper`}>
                            <span
                              className="form-control__label u-mrb">{t('partner.form.commission.partner-commission')}</span>
                            <input type="checkbox"
                                   id={`commission-partner-${rel.id}`}
                                   name={`commission-partner-${rel.id}`}
                                   checked={rel.commissioned}
                                   disabled
                                   className="checkbox-toggle checkbox-toggle--light no-skin checkbox-toggle-radio-default"
                            />
                            <label className="checkbox-toggle__button" htmlFor={`commission-partner-${rel.id}`}/>
                          </div>
                        </div>
                        <div className="form-resume__expend flex">
                          <img src={iconRemove} alt="" onClick={() => dispatch(deleteCommissionRelation(rel))}/>
                        </div>
                      </div>
                    </div>
                  </div>
                })}
              </div>
            </>
          }
        </div>
      </div>
      {!isLectureMode && <FooterBlock disabled={isLoading} onCancelUrl={t('url.partner.dashboard')}/>}
    </form>
  )
}

export default Commissions

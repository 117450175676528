import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../store/hook";
import HeaderPage from "../../../component/HeaderPage/HeaderPage";
import TabRecurrence from "../../../component/Tab/Recurrence/TabRecurrence";
import {useNavigate} from "react-router-dom";
import RecurrenceSessionDatalist from "../../../component/Datalist/Recurrence/RecurrenceSessionDatalist";


const RecurrenceSession: FunctionComponent = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)
  const recurrenceRules = useAppSelector((state) => state.me.me?.rules.recurrence)

  if(!recurrenceRules?.visible) navigate('/')

  return (
    <>
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
      <HeaderPage 
          title={t('recurrence.title')} 
          addLink={recurrenceRules?.actions?.create ? `/${t('url.recurrence.session.add')}` : undefined}
          tooltipLink={`${t('recurrence.session.add.title')}`}
        />
        <TabRecurrence/>
        <RecurrenceSessionDatalist/>
      </main>
    </>
  )
}

export default RecurrenceSession
